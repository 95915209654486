/* eslint-disable prettier/prettier */
/* eslint-disable prefer-destructuring */
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { combineLatest, forkJoin, Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { RFIDocumentsService } from "../api-generated/api/rFIDocuments.service";
import { RFIItemService } from "../api-generated/api/rFIItem.service";
import IRFIViewPrepare from "../models/IRFIViewPrepaer";
import RFIStatus from "../models/rfiStatus";
import LoadingService from "../services/loading.service";
import LocalContractUserService from "../services/local-contractUser.service";
import RouteParams from "../shared/route-params";
import { canEditRFI, getRFIlink, isReviewer } from "@shared/utils";
import BaseFunctionService from "@services/baseFunction.service";
import RFICompilingStep from "@models/rfiCompilingStep";
import { FileModel } from "@aecom/core";
import _ from "lodash";
import RFIDocumentCopy from "@models/rfiDocumentCopy";
import { IPRFIDocumentDownload, IPRFIItem } from "../api-generated";
import { AuthService } from "@bbj/components";

@Injectable({
  providedIn: "root"
})
export default class RFIViewResolver {
  constructor(
    public router: Router,
    private authService: AuthService,
    private loadingService: LoadingService,
    private rfiItemService: RFIItemService,
    private rfiDocumentService: RFIDocumentsService,
    public localContractUserSerice: LocalContractUserService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IRFIViewPrepare | undefined> {
    this.loadingService.start();
    const currentUserId = this.authService.getUserId();

    const rfiid = route.params[RouteParams.ItemId];
    const contractId = route.parent.params[RouteParams.ContractId];
    const role = this.localContractUserSerice.currentUserContractRole;
    const createNewURL = `/${contractId}/create/new`;
    if(state.url === createNewURL)
    {
      this.baseFunctionService.setContractId(contractId);
      this.baseFunctionService.initFileList();
      return of(undefined);
    }

    return forkJoin([
      this.rfiItemService.getRFIItem(rfiid),
      this.rfiDocumentService.getAllRFIDocuments(rfiid)
    ]).pipe(
      mergeMap(([rfi, rfiDocuments]) => {
        const reportUrl = `/${contractId}/report/${rfiid}`;
        if (rfi.Status !== RFIStatus.Responded || state.url !== reportUrl) {
          const hasReviewerRole = isReviewer(rfi, currentUserId);
          const viewOnly = !canEditRFI(rfi, role, currentUserId, hasReviewerRole);
          let newURL = `/${contractId}/view/${rfi.Guid}`;
          let altUrl = '';

          if (!viewOnly) {
            newURL =
              getRFIlink(
                contractId,
                rfi,
                viewOnly,
                hasReviewerRole
              );
          }
          if(rfi.Status === RFIStatus.QAQC_Fail)
          {
            altUrl = `/${contractId}/create/${rfi.Guid}`;
          }
          if(currentUserId === rfi.CoordinatorId && rfi.Status === RFIStatus.Review_Period)
          {
            altUrl = `/${contractId}/compilingResponse/${rfi.Guid}`;
          }
          if(state.url !== newURL && state.url !== altUrl) {
            this.router.navigateByUrl(newURL);
          }
        }
        this.baseFunctionService.setEntity(rfi, rfiDocuments);
        this.baseFunctionService.initFileList();
        if(state.url === `/${contractId}/compilingResponse/${rfi.Guid}`)
        {
          const compile = new RFICompilingStep(contractId, rfi);
          this.baseFunctionService.setTempId(compile.getReferenceId());
          let fileList: FileModel[] =[];
          if(compile.rfi_compiled_response.Guid)
          {
            fileList = this.baseFunctionService.getFileListForCurrentStep(compile.Status, compile.rfi_compiled_response.Guid);
          } else if(rfi.Status !== RFIStatus.Review_Period) {
            const compiles = rfi.rfi_compiled_response?.sort(
              (a, b) =>
                new Date(b.DateUpdated).getTime() - new Date(a.DateUpdated).getTime(),
            );
            if(compiles && compiles.length>0)
            {
              const lastCompile = compiles[0];
              const documnetsForThis = this.baseFunctionService.getFileListForCurrentStep(lastCompile.Status, lastCompile.Guid);
              if(documnetsForThis.length > 0)
              {
                return combineLatest([of(rfi), of(rfiDocuments), of(compile), 
                  forkJoin(
                    documnetsForThis.map((item)=>{
                      return this.rfiDocumentService
                        .copyRFIDocument(
                          new RFIDocumentCopy(
                            compile.Status,
                            compile.Guid,
                            item.Guid,
                            compile.getReferenceId()
                          )
                        )
                    })
                  ).pipe(
                    map((res) =>{
                      const fileList = this.baseFunctionService.getBBJFileListItemAttachment();
                      const newDocs = documnetsForThis.map((item, i)=>{
                        item.Percentage = 100;
                        item.Status = "preloaded";
                        item.Guid = res[i].Guid;
                        fileList.updateFileList(item);
                        return item;
                      });
                      compile.docSave = newDocs.map((item)=>{return item.Guid}).filter((id)=>{return !rfiDocuments.find((doc)=>{return doc.Guid === id})});
                      return newDocs;
                    })
                  )
                ]);
              }
              
            }
          }
          return combineLatest([of(rfi), of(rfiDocuments), of(compile), of(fileList)])
        } else {
          return combineLatest([of(rfi), of(rfiDocuments), of(undefined), of(undefined)])
        }
      }),
      map(([rfi, rfiDocuments, compile, compileDocuments]: [IPRFIItem, IPRFIDocumentDownload[], RFICompilingStep | undefined, FileModel[] | undefined]) => {
        return { rfi, rfiDocuments, compile, compileDocuments };
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        this.router.navigate([contractId, "error", error.status ? error.status : 500]);
        return of(null);
      }),
    );
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  // checkDocTypeAndRedirect(
  //   rfi: IPRFIListItem,
  //   rfiRole: string,
  //   currentUrl: string,
  //   contractId: string,
  //   currentUserId: string,
  // ): void {
  //   let newURL = "";
  //   const status = rfi.IsRecalled ? RFIStatus.Recalled : rfi.Status;
  //   const rfiId = rfi.Guid;
  //   const ballInCourt = rfi.BallInCourt;

  //   switch (status) {
  //     case RFIStatus.Draft:
  //       newURL = `/${contractId}/rfi/${rfiId}`;
  //       break;

  //     case RFIStatus.In_Review:
  //       if (ballInCourt === RFIRole.PMC_Doc_Control) {
  //         newURL = `/${contractId}/inReviewqaqcRFI/${rfiId}`;
  //       } else {
  //         newURL = `/${contractId}/inReviewAssign/${rfiId}`;
  //       }
  //       break;
  //     case RFIStatus.Compiling:
  //       if (currentUserId === rfi.CoordinatorId || rfiRole === RFIRole.PMC_Doc_Control) {
  //         newURL = `/${contractId}/compilingRFI/${rfiId}`;
  //       } else {
  //         newURL = `/${contractId}/reviewRFI/${rfiId}`;
  //       }
  //       break;
  //     case RFIStatus.DDC_Review:
  //       newURL = `/${contractId}/ddcReviewRFI/${rfiId}`;
  //       break;
  //     case RFIStatus.DDC_Rejected:
  //       newURL = `/${contractId}/inReviewAssign/${rfiId}`;
  //       break;

  //     case RFIStatus.Final_Review:
  //       if (ballInCourt === RFIRole.PMC_Manager) {
  //         newURL = `/${contractId}/finalResponseRFI/${rfiId}`;
  //       } else {
  //         newURL = `/${contractId}/compilingRFI/${rfiId}`;
  //       }
  //       break;
  //     case RFIStatus.Rejected:
  //       if (currentUrl !== `/${contractId}/rfi/${rfiId}`) {
  //         // if (currentUrl !== `/${contractId}/reviseRFI/${rfiId}`) {
  //         newURL = `/${contractId}/viewonly/${rfiId}`;
  //       } else {
  //         newURL = currentUrl;
  //       }
  //       break;
  //     case RFIStatus.Recalled:
  //       newURL = `/${contractId}/viewonly/${rfiId}`;
  //       break;
  //     case RFIStatus.Responded:
  //     case RFIStatus.Canceled:
  //     case RFIStatus.Superseded:
  //     default:
  //       newURL = `/${contractId}/list`;
  //       break;
  //   }

  //   if (currentUrl !== newURL) {
  //     this.router.navigateByUrl(newURL);
  //   }
  // }
}
